import 'react-datepicker/dist/react-datepicker.css'

import styled from '@emotion/styled'
import fi from 'date-fns/locale/fi'
import { Router } from 'found'
import { TFunction } from 'i18next'
import DatePicker, { registerLocale } from 'react-datepicker'

import moment from '../../lib/moment-fi'
import { theme } from '../../Theme'
import { Shift } from '../../types'
import { ActivateFunction } from '../../types/App'
import { SearchDeleteParams, User } from '../../types/Input'
import LoadingIndicator from '../LoadingIndicator'
import CardWithSpace from '../search/card/CardWithSpace'
import DateContainer from '../search/DateContainer'
import SearchBox from '../search/SearchBox'
import SearchButton from '../search/SearchButton'
import SearchContainer from '../search/SearchContainer'
import SearchHeader from '../search/SearchHeader'
import SearchHistoryHeader from '../search/SearchHistoryHeader'
import SearchInput from '../search/SearchInput'
import SearchPageHeader from '../search/SearchPageHeader'

const UpperSearchContainer = styled.div`
  margin-top: 20px;
  margin-bottom: ${theme.spacing.sizes.smaller};
`

type Props = {
  activateDate: () => void
  activateShiftId: () => void
  activateStartStation: () => void
  activateTrainNumber: ActivateFunction
  changeDate: (date: Date | null) => void
  changeTrainNumber: (value: string) => void
  changeShiftId: (shiftId: string) => void
  changeStartStation: (startStation: string) => void
  fetchShifts: () => void
  searchedShiftFromHistory: (shift: Shift, func: () => unknown) => unknown
  deleteSearchedShiftFromHistory: (searchDeleteParams: SearchDeleteParams) => unknown
  loading: boolean
  router: Router
  setError: (arg0: string) => void
  shiftHistory: Array<Shift>
  date: string
  dateActive: boolean
  trainNumber: string
  shiftId: string
  startStation: string
  trainNumberActive: boolean
  shiftIdActive: boolean
  startStationActive: boolean
  t: TFunction
  user: User
}

const ShiftSearch = ({
  activateStartStation,
  activateShiftId,
  changeShiftId,
  changeStartStation,
  activateTrainNumber,
  changeTrainNumber,
  activateDate,
  changeDate,
  t,
  loading,
  fetchShifts,
  shiftHistory,
  router,
  searchedShiftFromHistory,
  deleteSearchedShiftFromHistory,
  setError,
  date,
  dateActive,
  trainNumber,
  shiftId,
  startStation,
  trainNumberActive,
  shiftIdActive,
  startStationActive,
  user,
}: Props) => {
  const isCommuter =
    user.actAsCommuter || user.commuter_driver || user.commuter_manager || user.commuter_conductor
  const validate = () => {
    if (
      (trainNumber !== '' && startStation !== '') ||
      shiftId !== '' ||
      (trainNumber !== '' && isCommuter)
    ) {
      return true
    }
    return false
  }

  registerLocale('fi', fi)

  return (
    <div
      onKeyDown={
        validate()
          ? (ev) => {
              return ev.keyCode === 13 && fetchShifts()
            }
          : undefined
      }
    >
      <SearchPageHeader name="shift" text={t('searchShifts')} />
      <UpperSearchContainer>
        <SearchBox
          onClick={activateTrainNumber}
          active={trainNumberActive}
          disabled={!trainNumberActive && (shiftId !== '' || shiftIdActive)}
          disableWidth={true}
        >
          <SearchHeader>{t('trainNumber')}</SearchHeader>
          <SearchInput
            type="number"
            value={trainNumber}
            onChange={(event) => changeTrainNumber(event.target.value)}
            disabled={!trainNumberActive && (shiftId !== '' || shiftIdActive)}
          />
        </SearchBox>
        <SearchBox
          onClick={activateShiftId}
          active={shiftIdActive}
          disabled={!shiftIdActive && (trainNumber !== '' || trainNumberActive)}
          disableWidth={true}
        >
          <SearchHeader>{t('shiftId')}</SearchHeader>
          <SearchInput
            type="text"
            value={shiftId}
            onChange={(event) => changeShiftId(event.target.value)}
            disabled={!shiftIdActive && (trainNumber !== '' || trainNumberActive)}
          />
        </SearchBox>
      </UpperSearchContainer>
      <SearchContainer>
        <SearchBox
          marginRight="8px"
          onClick={activateStartStation}
          active={startStationActive}
          disabled={isCommuter}
          disableWidth={true}
        >
          <SearchHeader>{t('startStation')}</SearchHeader>
          <SearchInput
            type="text"
            value={startStation}
            onChange={(event) => changeStartStation(event.target.value)}
            disabled={isCommuter}
          />
        </SearchBox>
        <SearchBox marginLeft="8px" onClick={activateDate} active={dateActive}>
          <SearchHeader>{t('startDay')}</SearchHeader>
          <SearchInput type="text" readOnly value={date} onChange={() => null} />
        </SearchBox>
      </SearchContainer>
      {dateActive && (
        <DateContainer>
          <DatePicker
            inline
            locale="fi"
            readOnly
            selected={new Date(moment(date, 'DD.MM.YYYY').toDate())}
            onChange={(value) => changeDate(value)}
          />
        </DateContainer>
      )}
      {loading ? (
        <LoadingIndicator size="normal" padded={true} />
      ) : (
        <SearchButton
          t={t}
          validate={validate}
          setError={setError}
          errorKey="fillAllMandatoryFields"
          onClick={fetchShifts}
        />
      )}
      {shiftHistory && shiftHistory.length > 0 && (
        <div>
          <SearchHistoryHeader>{t('lastSearch')}</SearchHistoryHeader>
          <div>
            {shiftHistory.map((shift) => (
              <CardWithSpace
                key={shift.id}
                t={t}
                textKey={'shiftHistoryResult'}
                args={{
                  id: shift.shiftId,
                  date: `${moment(shift.startDateTime).format('DD.MM.YYYY')}`,
                }}
                onClick={() =>
                  searchedShiftFromHistory(shift, () =>
                    router.push(
                      `/shiftInstructions/${shift.id}/${moment(shift.startDateTime).format(
                        'DD.MM.YYYY'
                      )}/cached`
                    )
                  )
                }
                deleteAction={() =>
                  deleteSearchedShiftFromHistory({
                    shiftId: shift.id,
                    date: moment(shift.startDateTime).format('YYYY-MM-DD'),
                  })
                }
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default ShiftSearch
