import { createAsyncThunk } from '@reduxjs/toolkit'

import { apiGET } from '../lib/api'
import { AppState } from '../types'

interface DefectsPageAllowedResponse {
  defectsPageAllowed?: boolean
}

const fetchDefectsPageAllowed = () =>
  apiGET<DefectsPageAllowedResponse>('/defects/defectsPageAllowed')

export const fetchDefectsPageAllowedAction = createAsyncThunk<
  DefectsPageAllowedResponse,
  void,
  { state: AppState }
>('user/fetchDefectsPageAllowed', async (_, thunkApi) => {
  const user = thunkApi.getState().user
  if (
    !user.commuter_conductor &&
    !user.commuter_driver &&
    !user.commuter_manager &&
    !user.actAsCommuter
  ) {
    return { defectsPageAllowed: true }
  }
  try {
    const response = await fetchDefectsPageAllowed()

    return response
  } catch {
    return {}
  }
})
