import styled from '@emotion/styled'
import { Router } from 'found'
import { TFunction } from 'i18next'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { fetchTimetable } from '../../actions/api'
import { checkInModal, openModal } from '../../actions/timetable'
import Download from '../../icons/Download'
import { isMobileSelector, shiftTasksToTimetableParams } from '../../Selectors'
import { color, getColor, LAYOUT_LEFT_EDGE, px, theme } from '../../Theme'
import { Action, AppState, Dispatch, Shift } from '../../types'
import { FullProps } from '../../types/App'
import { CheckedModal as ModalType, TimetableParams } from '../../types/Input'
import LoadingIndicator from '../LoadingIndicator'
import Text from '../Text'
import UsedForDrivingModal from './UsedForDrivingModal'

interface DownloadButtonProps extends FullProps {
  buttonMode: boolean
}

const Container = styled.div<FullProps>`
  ${(p) =>
    !p.full
      ? `flex: 0 1 19%;`
      : `display: flex;
    justify-content: space-between;
    margin: 0 12px 0 ${px(LAYOUT_LEFT_EDGE)};`}
`

const SpacedRow = styled.div<FullProps>`
  ${theme.layout.flexRow};
  justify-content: space-between;
  color: ${(p) => getColor(p.theme, ['black'], ['grayPink'])};
  ${(p) => p.full && `width: 100%;`}
`

const DownloadButton = styled.button<DownloadButtonProps>`
  ${(p) =>
    p.full ? theme.text('smaller', 'decorative', 'bold') : theme.text('normal', 'content', 'bold')};
  ${(p) => theme.spacing.sides(p.full ? 'large' : 'smallest')};
  ${(p) => theme.spacing.ends(p.full ? 'small' : 'tiny')};
  background: ${color('primaryBlue')};
  border: none;
  border-radius: ${theme.borderRadius.button};
  color: ${color('white')};
  cursor: pointer;
  text-transform: ${(p) => (p.full ? 'uppercase' : 'none')};
  letter-spacing: ${(p) => (p.full ? px(0.8) : 0)};
  width: ${(p) => (p.buttonMode ? `100%` : `70px`)};
  height: 34px;
  max-height: 34px;
  &[disabled] {
    color: ${(p) => getColor(p.theme, ['white'], ['grayPink'])};
    background: ${(p) => getColor(p.theme, ['shallowBlue'], ['nightGray'])};
  }
`

type Props = {
  t: TFunction
  shift: Shift
  full: boolean
  router?: Router
  fetchTimetable: (arg0: boolean, array: Array<TimetableParams>) => void
  isMobile: boolean
  timetableParams: Array<TimetableParams>
  loading: boolean
  modal: ModalType
  openModal: (arg0: boolean, array: Array<TimetableParams>) => void
  checkInModal: (arg0: boolean) => void
  allowTimetableFetch: boolean
}

function TimetableRow({
  t,
  timetableParams,
  shift,
  full,
  fetchTimetable,
  loading,
  modal,
  openModal,
  checkInModal,
  isMobile,
  allowTimetableFetch,
}: Props) {
  if (!timetableParams || timetableParams.length === 0 || !allowTimetableFetch) {
    return null
  }

  return (
    <Container full={full}>
      <UsedForDrivingModal
        modal={modal}
        fetchTimetable={fetchTimetable}
        openModal={openModal}
        timetableParams={timetableParams}
        checkInModal={checkInModal}
        isMobile={isMobile}
      />
      <SpacedRow full={full}>
        <SpacedRow>
          {shift && <Text>{t('timetableForShift', { number: shift.shiftId })}</Text>}
        </SpacedRow>
        <DownloadButton buttonMode={!shift} onClick={() => openModal(true, timetableParams)}>
          {loading ? (
            <LoadingIndicator size="small" padded={false} />
          ) : (
            <Download iconSize="smallest" />
          )}
        </DownloadButton>
      </SpacedRow>
    </Container>
  )
}

type PropsIn = {
  t: TFunction
  shift: Shift
  timetableParams?: TimetableParams
}

const mapStateToProps = (state: AppState, { shift, timetableParams }: PropsIn) => {
  const isMobile = isMobileSelector(state)

  const params = shift
    ? shiftTasksToTimetableParams(shift)
    : timetableParams
    ? [timetableParams]
    : []
  const paramsFromState = state.timetable.usedTimetableParams

  return {
    isMobile,
    timetableParams: paramsFromState && paramsFromState.length > 0 ? paramsFromState : params,
    loading: state.timetable.loading,
    modal: state.timetable.modal,
    allowTimetableFetch:
      state.user.admin ||
      state.user.commuter_driver ||
      state.user.commuter_manager ||
      state.user.driver ||
      state.user.logistics_driver ||
      state.user.conductor ||
      state.user.other,
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTimetable: (isUsedForDriving: boolean, parts: TimetableParams[]) =>
    dispatch(fetchTimetable(isUsedForDriving, parts) as unknown as Action),
  openModal: (isOpen: boolean, usedTimetableParams: TimetableParams[]) =>
    dispatch(openModal({ isOpen, usedTimetableParams })),
  checkInModal: (checked: boolean) => dispatch(checkInModal(checked)),
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TimetableRow))
