import styled from '@emotion/styled'
import { Router } from 'found'
import { TFunction } from 'i18next'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { TypedDispatch } from '..'
import { fetchFindings } from '../actions/api'
import { fetchEquipmentsAction } from '../actions/defect-common'
import { fetchDefectsPageAllowedAction } from '../actions/user'
import Button from '../components/button/Button'
import EquipmentSelectionDialog from '../components/create-defect/EquipmentSelectionDialog'
import { dataWithStatus, Equipment } from '../components/create-defect/types'
import EquipmentScrollWrapper from '../components/EquipmentScrollWrapper'
import ErrorText from '../components/ErrorText'
import FindingList from '../components/findings/FindingList'
import RestrictionIcon from '../components/findings/RestrictionIcon'
import LoadingIndicator from '../components/LoadingIndicator'
import {
  Container,
  Content,
  Footer,
  FooterText,
  HeaderText,
  PageHeader,
} from '../components/page/PageComponents'
import ArrowDown from '../icons/ArrowDown'
import ArrowUp from '../icons/ArrowUp'
import Close from '../icons/Close'
import { findMostCriticalFaultLevel } from '../lib/findings'
import { setPreSelectedSerialNumber } from '../reducers/createDefectSlice'
import { findingsSelector, nightModeSelector, selectEquipmentFindings } from '../Selectors'
import { defaultTextColor, getColor, Theme, theme } from '../Theme'
import { AppState, FindingsState, Moment, TaskEquipment } from '../types'

const HeaderContainer = styled.div`
  ${theme.layout.fluidWidth(theme.maxWidths.content)};
  @media (min-width: ${theme.maxWidths.content}) {
    width: 432px;
  }
`

const BackIcon = styled.div`
  align-content: start;
  display: flex;
  padding-left: 8px;
  padding-right: 8px;
`

const ColumnHeading = styled.div`
  ${theme.text('smaller')};
  color: ${(p) => getColor(p.theme, ['grayDark'], ['grayPink'])};
  width: 25%;
`

const Heading = styled.div`
  ${theme.layout.flexRow};
  background-color: ${(p) => getColor(p.theme, ['white'], ['grayDark'])};
  padding-bottom: 8px;
`

const Bold = styled.div`
  ${theme.text('normal')};
  font-weight: bold;
  width: 25%;
  text-align: center;
`

const IconContainer = styled.div`
  flex-direction: row;
  display: flex;
  width: 45%;
  justify-content: flex-end;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
`

const Row = styled.div`
  padding-top: 16px;
  ${theme.layout.flexRow};
  padding-bottom: 32px;
  color: ${defaultTextColor};
`

const EquipmentContainer = styled.div`
  width: 100%;
  background: ${(p) => getColor(p.theme, ['white'], ['grayDark'])};
  left: 0;
  right: 0;
  ${theme.layout.flexRow};
  padding: 8px;
`

const FindingContainer = styled(EquipmentScrollWrapper)`
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
`

const ArrowContainer = styled.div`
  margin-left: 16px;
  display: flex;
  align-items: center;
`

interface ActionButtonProps {
  width?: string
  marginBottom?: string
  marginTop?: string
}

const ActionButton = styled(Button)<ActionButtonProps>`
  width: ${(p) => p.width ?? '50%'};
  height: 62px;
  margin-bottom: ${(p) => p.marginBottom ?? '0'};
  margin-top: ${(p) => p.marginTop ?? '0'};
`

type State = {
  equipmentId: string | null
  showCreateDefectModal: boolean
}

type Props = {
  t: TFunction
  router: Router
  now: Moment
  isMobile: boolean
  nightMode: boolean
  equipmentIds: Array<string>
  taskEquipment: TaskEquipment | null
  theme: Theme
  equipments: dataWithStatus<Equipment[]>
  fetchFindings: (array: Array<string>) => unknown
  setPreselectedSerialNumber: (arg0: string) => void
  fetchEquipments: () => void
  showCreateDefectButton: boolean
  fetchDefectsPageAllowed: () => void
}

class RestrictionInfoPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      equipmentId: null,
      showCreateDefectModal: false,
    }

    this.toggleCreateDefectModal = this.toggleCreateDefectModal.bind(this)
  }

  componentDidMount() {
    const {
      taskEquipment,
      equipments,
      equipmentIds,
      fetchFindings,
      fetchEquipments,
      showCreateDefectButton,
    } = this.props

    if (!taskEquipment || taskEquipment.findings.length === 0) fetchFindings(equipmentIds)
    if (!equipments?.data?.length) fetchEquipments()
    if (showCreateDefectButton === undefined) this.props.fetchDefectsPageAllowed()
  }

  back() {
    window.history.back()
  }

  openFindings(equipmentId: string | null) {
    this.setState({
      equipmentId: equipmentId === this.state.equipmentId ? null : equipmentId,
    })
  }

  toggleCreateDefectModal() {
    this.setState({ showCreateDefectModal: !this.state.showCreateDefectModal })
  }

  goToCreateDefectPage() {
    this.props.router.push('/create-defect')
  }

  handleCreateDefectEvent(equipments: string[]) {
    const { setPreselectedSerialNumber } = this.props

    if (equipments.length <= 1) {
      if (equipments.length === 1) setPreselectedSerialNumber(equipments[0])
      this.goToCreateDefectPage()
    } else {
      this.toggleCreateDefectModal()
    }
  }

  render() {
    const {
      t,
      nightMode,
      taskEquipment,
      setPreselectedSerialNumber,
      equipmentIds,
      equipments,
      showCreateDefectButton,
    }: Props = this.props
    const { error, loading, findings } = taskEquipment || {
      error: false,
      loading: true,
      highestCriticality: null,
      findings: [],
    }

    const filterEquipments = (
      equipments: Equipment[] | undefined,
      equipmentIds: string[]
    ): string[] | undefined =>
      equipmentIds?.filter((eid) => equipments?.some((e) => e.installationGroup === eid)) || []

    const filteredEquipments = filterEquipments(equipments.data, equipmentIds)

    return (
      <Container id="scroll-container">
        <PageHeader flexDirection="column">
          <HeaderContainer>
            <Row>
              <HeaderText>{t('restrictionInfos')}</HeaderText>
              <BackIcon onClick={() => this.back()}>
                <Close
                  iconSize="normal"
                  fillColor={getColor({ nightMode }, ['primaryBlue'], ['primaryBlue'])}
                />
              </BackIcon>
            </Row>
            <Heading>
              <ColumnHeading>{t('equipment')}</ColumnHeading>
              <ColumnHeading>{t('reportingGroup')}</ColumnHeading>
            </Heading>
          </HeaderContainer>
        </PageHeader>
        {loading ? (
          <LoadingIndicator size="normal" padded={true} />
        ) : (
          <Content>
            {error && <ErrorText>{t(error as string)}</ErrorText>}
            {!error && (!findings || findings.length === 0) && (
              <ErrorText>{t('fetchFindingsNoneFound')}</ErrorText>
            )}
            {this.state.showCreateDefectModal && (
              <EquipmentSelectionDialog
                closeDialog={this.toggleCreateDefectModal}
                submitHandler={(selectedSerialNumber) => {
                  setPreselectedSerialNumber(selectedSerialNumber)
                  this.goToCreateDefectPage()
                }}
                dialogText={t('defect.selectEquipment')}
                primaryActionText={t('defect.createNew')}
                equipmentIds={filteredEquipments || []}
              />
            )}
            {!error &&
              findings &&
              findings.length > 0 &&
              findings.map((equipmentWithFindings) => (
                <FindingContainer
                  key={equipmentWithFindings.equipment.equipmentId}
                  ownId={equipmentWithFindings.equipment.equipmentId}
                  equipmentId={this.state.equipmentId || ''}
                >
                  <EquipmentContainer
                    onClick={() => this.openFindings(equipmentWithFindings.equipment.equipmentId)}
                  >
                    <Bold>{equipmentWithFindings.equipment.equipmentId}</Bold>
                    <Bold>{equipmentWithFindings.equipment.reportingGroup}</Bold>
                    <IconContainer>
                      <RestrictionIcon
                        criticality={findMostCriticalFaultLevel(equipmentWithFindings) || ''}
                        usageCode={
                          equipmentWithFindings.faults.find((fault) => {
                            return fault.usageCode !== null
                          })?.usageCode || ''
                        }
                        nightMode={nightMode}
                        status="open"
                      />
                      <ArrowContainer>
                        {this.state.equipmentId === equipmentWithFindings.equipment.equipmentId ? (
                          <ArrowUp
                            iconSize="normal"
                            stroke={getColor({ nightMode }, ['primaryBlue'], ['primaryBlue'])}
                          />
                        ) : (
                          <ArrowDown
                            iconSize="normal"
                            stroke={getColor({ nightMode }, ['primaryBlue'], ['primaryBlue'])}
                          />
                        )}
                      </ArrowContainer>
                    </IconContainer>
                  </EquipmentContainer>
                  {this.state.equipmentId === equipmentWithFindings.equipment.equipmentId && (
                    <FindingList faults={equipmentWithFindings.faults} nightMode={nightMode} />
                  )}
                </FindingContainer>
              ))}
            {Boolean(showCreateDefectButton) && (
              <ActionButton
                marginTop="30px"
                width="100%"
                onClick={() => this.handleCreateDefectEvent(filteredEquipments || [])}
              >
                {t('defect.createNew')}
              </ActionButton>
            )}
          </Content>
        )}
        <Footer onClick={() => window.history.back()}>
          <FooterText>{t('close')}</FooterText>
        </Footer>
      </Container>
    )
  }
}

const mapStateToProps = (state: AppState) => {
  const findingsState: FindingsState = findingsSelector(state)
  const { equipmentIds } = state.found.match.params
  const splitEquipment: Array<string> = equipmentIds
    ? equipmentIds.split(',').filter((lid) => lid.match(/^[0-9]+$/) !== null)
    : []

  const taskEquipment: TaskEquipment | null = selectEquipmentFindings(findingsState, splitEquipment)

  return {
    taskEquipment,
    nightMode: nightModeSelector(state),
    equipmentIds: splitEquipment,
    equipments: state.defectCommonData.equipments,
    showCreateDefectButton: state.user.defectsPageAllowed,
  }
}

const mapDispatchToProps = (dispatch: TypedDispatch) => ({
  fetchFindings: (equipments: string[]) => dispatch(fetchFindings(equipments)),
  setPreselectedSerialNumber: (serialNumber: string) =>
    dispatch(setPreSelectedSerialNumber(serialNumber)),
  fetchEquipments: () => dispatch(fetchEquipmentsAction(null)),
  fetchDefectsPageAllowed: () => dispatch(fetchDefectsPageAllowedAction()),
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(RestrictionInfoPage))
