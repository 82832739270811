import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { fetchDefectsPageAllowedAction } from '../actions/user'
import { Session, UserState } from '../types'
import { PersonnelGroup } from '../types/Input'

const initialState: UserState = {
  created_at: 0,
  token: '',
  number: '',
  actAsCommuter: false,
  actAsMaintenance: false,
  actAsServiceDriver: false,
  personnelGroup: '',
  serviceDriver: false,
  originalNumber: '',
  admin: false,
  read_admin: false,
  commuter_driver: false,
  commuter_manager: false,
  driver: false,
  logistics_driver: false,
  commuter_conductor: false,
  conductor: false,
  waiter: false,
  maintenance: false,
  other: false,
  name: '',
  familyName: '',
  loading: false,
  error: '',
} as const

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateSession(state, action: PayloadAction<Session>) {
      return {
        ...state,
        ...action.payload,
        defectsPageAllowed: undefined,
      }
    },
    updatePersonnelGroup(state, action: PayloadAction<PersonnelGroup>) {
      return {
        ...state,
        personnelGroup: action.payload,
      }
    },
    updateServiceDriverRole(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        serviceDriver: action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDefectsPageAllowedAction.fulfilled, (state, { payload }) => ({
      ...state,
      defectsPageAllowed: payload.defectsPageAllowed,
    }))
  },
})

export const { updateSession, updatePersonnelGroup, updateServiceDriverRole } = userSlice.actions
export default userSlice.reducer
